import React, { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { codeFont, header } from '../style'
import { P } from './common'
import { blue } from '../colors'
import { useScale } from '../scale'

type ApiAttributeProps = {
  name: string
  type: ReactNode
  required?: 'required' | 'optional'
  description: ReactNode
  namespace?: string
}

export const ApiAttribute: FC<ApiAttributeProps> = ({
  name,
  type,
  required,
  description,
  namespace = '',
  children,
}) => {
  const { applyScale } = useScale()
  const id = (namespace && `${namespace}-`) + name

  return (
    <div
      css={css`
        margin: 0.9em 0;
      `}
    >
      <div
        css={[
          css`
            font-weight: 500;
            margin-bottom: ${applyScale(0.1)}em;
            margin-top: ${applyScale(1.45)}em;
          `,
          header,
        ]}
        id={id}
      >
        {name} {required && `(${required})`}
      </div>
      <div
        css={[
          css`
            font-size: ${applyScale(0.88)}em;
            margin-top: ${applyScale(0.1)}em;
            color: ${blue};
          `,
          codeFont,
        ]}
      >
        {type}
      </div>
      {typeof description === 'string' ? <P>{description}</P> : description}
      {children}
    </div>
  )
}
