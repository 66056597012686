import React, { FC } from "react"
import { css } from "@emotion/react"

export const ChildAttributes: FC = ({ children }) => <div>{children}</div>

const boxCss = css`
  align-items: stretch;
  margin: 1.05em 0;
  border: 1px solid rgb(202, 215, 255);
  background-color: rgba(202, 215, 255, 0.2);
  border-left-width: 8px;
  padding: 0.8rem;
  border-radius: 4px;
`
