import styled from "@emotion/styled"
import { blue } from "../colors"
import { codeFont } from "../style"

export const P = styled.p`
  margin-bottom: 0.6rem;
`

export const Ul = styled.ul`
  & li {
    margin-bottom: calc(1.45rem / 4);
  }
`

export const Code = styled.span`
  ${codeFont};
  font-size: 0.9em;
  color: ${blue};
`
