import React, { FC } from "react"
import { css } from "@emotion/react"
import { codeFont } from "../style"

type SignatureProps = {
  text: string
}

export const Signature: FC<SignatureProps> = ({ text }) => {
  return <div css={codeFont}>{text}</div>
}
