import React, { FC, ReactNode } from 'react'
import { css } from '@emotion/react'

export type FieldProps = {
  name: ReactNode
}

export const Field: FC<FieldProps> = ({ name, children }) => (
  <label
    css={css`
      display: flex;
      flex-direction: column;
    `}
  >
    <span>{name}</span>
    {children}
  </label>
)
