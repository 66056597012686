import React, { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlag } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type NoteProps = {
  headerText?: ReactNode
  icon?: IconProp
}

export const Note: FC<NoteProps> = ({ headerText = 'Note', icon, children }) => {
  return (
    <div
      css={css`
        display: flex;
        gap: 1rem;
        padding: 1rem;
        border: 1px solid rgb(202, 215, 255);
        background-color: rgba(202, 215, 255, 0.2);
        border-radius: 4px;
        margin: 1.45rem 0;
      `}
    >
      {icon ? <FontAwesomeIcon icon={icon} /> : <FontAwesomeIcon icon={faFlag} />}
      <div>
        <h4
          css={css`
            margin-top: 0;
          `}
        >
          {headerText}
        </h4>
        {children}
      </div>
    </div>
  )
}
