import React, { FC } from 'react'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTools } from '@fortawesome/free-solid-svg-icons'
import { blue } from '../colors'
import { ScaleContext } from '../scale'

type ObjectAttributeProps = {
  name: string
}

export const ObjectAttribute: FC<ObjectAttributeProps> = ({
  children,
  name,
}) => (
  <div
    css={css`
      display: flex;
    `}
  >
    <FontAwesomeIcon
      css={css`
        margin-top: 0.9em;
        color: ${blue};
      `}
      icon={faTools}
    />
    <div
      css={css`
        padding: 0.8em;
        padding-left: 0.5em;
      `}
    >
      <h4
        css={css`
          margin-top: 0;
          margin-bottom: 0.7rem;
          font-weight: 500;
          color: ${blue};
        `}
      >
        {name} object:
      </h4>
      <ScaleContext.Provider value={1}>{children}</ScaleContext.Provider>
    </div>
  </div>
)
