import { Fragment } from 'react';
import { HtmlAttribute } from "../../../../src/components/attribute/html-attribute";
import { ApiAttribute } from "../../../../src/components/attribute/api-attribute";
import { ObjectAttribute } from "../../../../src/components/attribute/object-attribute";
import { SubType } from "../../../../src/components/attribute/sub-type";
import { P, Ul, Code } from "../../../../src/components/attribute/common";
import { ChildAttributes } from "../../../../src/components/attribute/child-attributes";
import { Signature } from "../../../../src/components/attribute/signature";
import { Note } from "../../../../src/components/note";
import * as React from 'react';
export default {
  Fragment,
  HtmlAttribute,
  ApiAttribute,
  ObjectAttribute,
  SubType,
  P,
  Ul,
  Code,
  ChildAttributes,
  Signature,
  Note,
  React
};