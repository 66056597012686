import React, { FC } from 'react'
import { css } from '@emotion/react'

export type IndentProps = {
  size?: number
}

export const Indent: FC<IndentProps> = ({ size = 1, children }) => (
  <div
    css={css`
      padding-left: ${1.5 * size}rem;
    `}
  >
    {children}
  </div>
)
