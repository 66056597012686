import React, { FC, useEffect, useState, useMemo, useRef } from 'react'
import { css } from '@emotion/react'
import Prism from 'prismjs'

const field = css`
  display: flex;
  flex-direction: column;
`

const tagHtml = ({ skus, sellerId }: { skus: string; sellerId: string }) => {
  const parsedSkus = JSON.stringify(
    skus.split(/\n/).filter(subject => subject !== '')
  )

  const el = document.createElement('deliverr-tag-extended')

  el.setAttribute('data-sellerid', sellerId)
  el.setAttribute('data-skus', parsedSkus)

  return el.outerHTML
}

export const Skus: FC = () => {
  useEffect(() => {
    Prism.highlightAll()
  }, [])

  const defaultSku = 'sku127'
  const defaultSellerId = 'seller'
  const [skus, setSkus] = useState(defaultSku)
  const [sellerId, setSellerId] = useState(defaultSellerId)
  const markup = useMemo(
    () => (typeof window !== 'undefined' ? tagHtml({ skus, sellerId }) : ''),
    [skus, sellerId]
  )
  const skusEl = useRef<HTMLTextAreaElement>(null)
  const sellerIdEl = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setSkus(skusEl.current!.value)
    setSellerId(sellerIdEl.current!.value)
    Prism.highlightAll()
  }, [markup])

  return (
    <div className="App markdown-section">
      <form
        css={css`
          display: flex;
          gap: 1rem;
        `}
      >
        <label
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <span>SKUs</span>
          <textarea
            name="skus"
            ref={skusEl}
            defaultValue={defaultSku}
            onChange={({ currentTarget }) => {
              setSkus(currentTarget.value)
            }}
          ></textarea>
        </label>
        <label
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <span>Seller ID</span>
          <input
            defaultValue={defaultSellerId}
            ref={sellerIdEl}
            type="text"
            name="sellerid"
            onChange={({ currentTarget }) => {
              setSellerId(currentTarget.value)
            }}
          />
        </label>
      </form>
      <pre>
        <code className="lang-html">{markup}</code>
      </pre>
    </div>
  )
}
