import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const leftContainer = css`
  padding-left: 1rem;
`

export const topContainer = css`
  padding-top: 1rem;
`

export const bottomContainer = css`
  padding-bottom: 1rem;
`

export const rightContainer = css`
  padding-left: 2rem;
  padding-right: 1rem;
`

export const header = css`
  font-family: Montserrat;
  font-weight: 500;
`

export const p = css`
  margin-top: 0.3em;
`

export const P = styled.p`
  margin-top: 0.3em;
`

export const codeFont = css`
  font-family: 'IBM Plex Mono', 'Inconsolata', Consolas, 'Andale Mono WT',
    'Andale Mono', 'Lucida Console', 'Lucida Sans Typewriter',
    'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Liberation Mono',
    'Nimbus Mono L', Monaco, 'Courier New', Courier, monospace;
`
