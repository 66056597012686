import React, { useEffect, useState, useMemo, useRef, FC } from 'react'
import Prism from 'prismjs'
import { Field } from './field'

const tagHtml = ({ dskus }: { dskus: string }) => {
  const parsedDskus = JSON.stringify(
    dskus.split(/\n/).filter(subject => subject !== '')
  )

  const el = document.createElement('deliverr-tag-extended')

  el.setAttribute('data-dskus', parsedDskus)
  return el.outerHTML
}

export const Dskus: FC = () => {
  useEffect(() => {
    Prism.highlightAll()
  }, [])
  const defaultSku = 'D1234567891'
  const [dskus, setDskus] = useState(defaultSku)
  const markup = useMemo(
    () => (typeof window !== 'undefined' ? tagHtml({ dskus }) : ''),
    [dskus]
  )
  const dskusEl = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    setDskus(dskusEl.current!.value)
    Prism.highlightAll()
  }, [markup])

  return (
    <div className="App markdown-section">
      <form>
        <Field name="DSKUs">
          <textarea
            name="skus"
            ref={dskusEl}
            defaultValue={defaultSku}
            onChange={({ currentTarget }) => {
              setDskus(currentTarget.value)
            }}
          ></textarea>
        </Field>
      </form>
      <pre>
        <code className="lang-html">{markup}</code>
      </pre>
    </div>
  )
}
