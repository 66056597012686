import { createContext, useContext } from "react"

export type Scale = number

const applyScaleWith = (scale: number) => (input: number) => input * scale

export const defaultScale: Scale = 1

export const ScaleContext = createContext<Scale>(defaultScale)

export const useScale = () => {
  const scale = useContext(ScaleContext)
  return {
    scale,
    applyScale: applyScaleWith(scale),
  }
}
