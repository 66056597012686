import React, { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { header } from '../style'

type HtmlAttributeProps = {
  name: string
  accepts: ReactNode
  defaults?: ReactNode
  required?: boolean
  description: ReactNode
  namespace?: string
}

const fieldName = css`
  font-weight: bold;
`
const defaultsToNone = (
  <span
    css={css`
      font-style: italic;
    `}
  >
    None
  </span>
)
export const HtmlAttribute: FC<HtmlAttributeProps> = ({
  name,
  accepts,
  defaults = defaultsToNone,
  required = false,
  description,
  namespace = '',
}) => {
  const id = (namespace && `${namespace}-`) + name

  return (
    <div
      css={css`
        margin: 0.9em 0;
      `}
    >
      <div
        css={[
          css`
            font-size: 0.95rem;
            margin-top: 1.45rem;
            margin-bottom: 0.3rem;
            font-weight: 500;
          `,
          header,
        ]}
        id={id}
      >
        {name} {required && `(required)`}
      </div>
      <div
        css={css`
          font-size: 0.88rem;
        `}
      >
        <span
          css={css`
            font-weight: 600;
          `}
        >
          Accepts:
        </span>{' '}
        {accepts}
      </div>
      <div
        css={css`
          font-size: 0.88rem;
        `}
      >
        <span
          css={css`
            font-weight: 600;
          `}
        >
          Default:
        </span>{' '}
        {defaults}
      </div>
      <div
        css={css`
          margin-top: 0.36rem;
          font-size: 0.95rem;
        `}
      >
        {typeof description === 'string' ? <p>{description}</p> : description}
      </div>
    </div>
  )
}
