export const red = `#FF3934`

export const black = `#02172c`

export const blue = `rgb(0, 51, 202)`

export const lightBlue = `rgb(202, 215, 255)`

export const blueBg = `#2d8ced`

export const blueBgHover = `rgb(92, 166, 241)`

export const white = `#fff`

export const navy = `#072f58`

export const darkGray = `#51596a`
