import React, { FC } from "react"
import { css } from "@emotion/react"

type SubTypeProps = {
  name: string
}

export const SubType: FC<SubTypeProps> = ({ name, children }) => {
  return (
    <div>
      <h4
        css={css`
          margin-bottom: 0.7rem;
          font-weight: 500;
        `}
      >
        {name} object:
      </h4>
      <div>{children}</div>
    </div>
  )
}
